import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';

function AccountSettingsModal({ open, onClose }) {
  const [interestRate, setInterestRate] = useState(0);
  const [paymentInterval, setPaymentInterval] = useState('Month');

  const handleSave = () => {
    // Logic to save the new settings (update state or send to backend)
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adjust Account Parameters</DialogTitle>
      <DialogContent>
        <TextField
          type="number"
          label="Interest Rate (%)"
          inputProps={{ min: 0, max: 100 }}
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Select
          value={paymentInterval}
          onChange={(e) => setPaymentInterval(e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value="Day">Day</MenuItem>
          <MenuItem value="Week">Week</MenuItem>
          <MenuItem value="Month">Month</MenuItem>
          <MenuItem value="Year">Year</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AccountSettingsModal;
