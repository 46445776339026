// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  IconButton,
  Button,
  Tooltip,
  Alert,
  Grid,
} from '@mui/material';


function HomePage() {
  const userName = 'John Doe'; // Replace with actual user data
  const accounts = [
    { id: 1, name: 'Élianne' },
    { id: 2, name: 'Francis' },
  ];

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <h1>Dino Banque</h1>
      </Box>
  
      <Box mt={2} border="1px solid #ccc" borderRadius={2} p={2}>
        <Grid container isplay="flex" justifyContent="space-between">
          {accounts.map((account) => (
            <Grid item key={account.id} xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="flex-end" justifyContent="center">
                <Link to={`/account/${account.id}`}>{account.name}</Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default HomePage;
