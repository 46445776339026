// AccountPage.js
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import TransactionModal from './TransactionModal';
import SettingsModal from './AccountSettingsModal';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import {
  Container,
  Box,
  Button,
  Typography,
  Grid2,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';



function AccountPage() {
  const { accountId } = useParams();
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [balance, setBalance] = useState(0);

  const account = {
    name: 'Savings Account',
    interestRate: 10,
  };


  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch('/api/transactions/1');
        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }
        const data = await response.json();
        setTransactions(data);
   
        const computedBalance = data.reduce((total, transaction) => {
          if (transaction.type === 'Deposit') {
            return total + transaction.amount;
          } else if (transaction.type === 'Withdrawal') {
            return total - transaction.amount;
          } else {
            return total;
          }
        }, 0);

        setBalance(computedBalance);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  // Group transactions by month
  const groupedTransactions = transactions.reduce((groups, transaction) => {
    const month = moment(transaction.date).format('MMMM YYYY');
    if (!groups[month]) groups[month] = [];
    groups[month].push(transaction);
    return groups;
  }, {});

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <IconButton component={Link} to="/">
          <HomeIcon />
        </IconButton>
        <IconButton onClick={() => setShowSettingsModal(true)}>
          <SettingsIcon />
        </IconButton>
      </Box>

      <Box my={4}>
        <Typography variant="h4" align="center">
          {account.name}
        </Typography>
        <Typography variant="subtitle1" align="center">
          Interest Rate: {account.interestRate}%
        </Typography>
        <Typography variant="subtitle1" align="center">
          Current Balance: ${balance.toFixed(2)}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" my={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowTransactionModal(true)}
        >
          Add Operation
        </Button>
      </Box>

      <Box>
        {Object.keys(groupedTransactions)
          .sort((a, b) => (moment(b, 'MMMM YYYY').isBefore(moment(a, 'MMMM YYYY')) ? -1 : 1))
          .map((month) => (
            <MonthGroup key={month} month={month} transactions={groupedTransactions[month]} />
          ))}
      </Box>

      {showTransactionModal && (
        <TransactionModal
          onClose={() => setShowTransactionModal(false)}
          onAddTransaction={(newTransaction) => setTransactions([...transactions, newTransaction])}
        />
      )}

      {showSettingsModal && <SettingsModal onClose={() => setShowSettingsModal(false)} />}

      <TransactionModal
        open={showTransactionModal}
        onClose={() => setShowTransactionModal(false)}
        onAddTransaction={(newTransaction) => setTransactions([...transactions, newTransaction])}
      />

      <SettingsModal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
    </Container>


  );

  function MonthGroup({ month, transactions }) {
    const [expanded, setExpanded] = useState(month === moment().format('MMMM YYYY'));

    return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{month}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid2 container spacing={2}>
            <table>
              {transactions
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((transaction) => (
                  <Grid2 item xs={12} key={transaction.id}>
                    <Box>

                      <Typography>
                        {transaction.date}: {transaction.type}  ${transaction.amount.toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
            </table>
          </Grid2>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default AccountPage;
